import React from 'react'
import { Base, FormSection, GridTypography, GridStatus, GridButton } from '../components/Common'
import { postAPI } from '../utils'

// eslint-disable-next-line
export default function () {
    const [isLoading, setIsLoading] = React.useState(false)

    const onTestClick = React.useCallback((event) => {
        if (event) {event.preventDefault()}
        setIsLoading(true)
        var body = {
            key: "value"
        }
        postAPI('/somepath', body)
        .then((res) => {
            setIsLoading(false)
            console.log(res)
        })
        .catch((err) => {
            setIsLoading(false)
            console.error(err)
        })
    }, [])

    return (
        <Base>
            <FormSection>
                <GridTypography>
                    Welcome to BBB
                </GridTypography>
                <GridButton 
                    isLoading={isLoading}
                    onClick={onTestClick}
                >
                    API Test
                </GridButton>
            </FormSection>
        </Base>
    )
}
