import React from 'react'
import { Base, FormSection, GridTypography, GridStatus } from '../components/Common'
import { EmailField, emailValid, AuthenticationCodeField } from '../components/Fields'
import { ContinueButton } from '../components/Buttons'
import { handleSuccessfullAuthResponse, postAPI } from '../utils'

// eslint-disable-next-line
export default function () {
    const [email, setEmail] = React.useState('')
    const [session, setSession] = React.useState('')
    const [authCode, setAuthCode] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const [inputError, setInputError] = React.useState('')
    const [inputSuccess, setInputSuccess] = React.useState('')

    const handleError = (err) => {
        console.error(err)
        setInputError("Something Went Wrong")
        setIsLoading(false)
    }

    const handleProcessing = () => {
        setIsLoading(true)
        setInputError('')
        setInputSuccess('')
    }

    const handleContinue = React.useCallback(() => {
        if (isLoading) {return}
        if (!session) {
            // If session is empty, we have yet to get a valid callback
            console.log("handling the first part of the authentication")
            if (!emailValid(email)) {return}
            handleProcessing()
            postAPI('/public/auth', {email: email})
                .then((res) => {
                    console.log(res.data)
                    setInputSuccess("Code Sent")
                    setIsLoading(false)
                    setSession(res.data.Session)
                })
                .catch(handleError)
        }
        else {
            console.log("handling the second part of the session")
            if (!authCode) {return}
            handleProcessing()
            var body = {
                email: email,
                session: session,
                code: authCode
            }
            postAPI('/public/auth', body)
                .then((res) => {
                    console.log(res.data)
                    setInputSuccess("Code Verified")
                    setIsLoading(false)
                    handleSuccessfullAuthResponse(res)
                })
                .catch(handleError)
        }
    }, [email, authCode, session, isLoading, setIsLoading, setSession])
    

    return (
        <Base>
            <FormSection>  
                <GridTypography>
                    Sign In
                </GridTypography>
                { !session ? (
                <EmailField
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                    onEnter={handleContinue}
                /> ) : (
                <AuthenticationCodeField 
                    value={authCode}
                    onChange={(e) => {setAuthCode(e.target.value)}}
                    onEnter={handleContinue}
                />
                )}
                <GridStatus 
                    success={inputSuccess}
                    error={inputError}
                />
                <ContinueButton 
                    isLoading={isLoading}
                    onClick={handleContinue}
                />
            </FormSection>
        </Base>
    )
}
