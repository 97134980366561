
import axios from 'axios';

import config from './config.json';

// Use dummy function as default
export default function pleaseStopMessingWithThis() {
    console.log('You shouldn\'t be here')
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function checkNested(obj, level,  ...rest) {
    if (obj === undefined) return false
    if (rest.length === 0 && obj.hasOwnProperty(level)) return true
    return checkNested(obj[level], ...rest)
}

/////////////////////////////////
// Common Values functionality //
/////////////////////////////////
export function getEndpoint() {
    let endpoint = window.location.host
    if (window.location.hostname.includes('localhost') || window.location.hostname.startsWith('192.168.')) {
        console.log("Running in development mode, targeting dev endpoint")
        endpoint = config.endpoint;
    }
    // if the source is not in my list of endpoints return none
    if (endpoint.endsWith("bulkbudbuyer.com")) {
        return endpoint
    }
    return null
}

export function getIdToken() {
    return getLocalStorageItem("IdToken")
}
export function getAccessToken() {
    return getLocalStorageItem("AccessToken")
}
export function getRefreshToken() {
    return getLocalStorageItem("RefreshToken")
}

export function getLocalStorageItem(name) {
    return localStorage.getItem(name)
}

export function handleSuccessfullAuthResponse(res) {
    if (!checkNested(res, 'data', 'AuthenticationResult')) {return}
    localStorage.setItem("IdToken", res.data.AuthenticationResult.IdToken)
    localStorage.setItem("AccessToken", res.data.AuthenticationResult.AccessToken)
    localStorage.setItem("RefreshToken", res.data.AuthenticationResult.RefreshToken)
    return
}

////////////////////////
// REST functionality //
////////////////////////

export function postAPI(path, body={}, requestConfig={}) {

    // Determine proper endpoint
    let endpoint = "https://" + getEndpoint() + "/api"

    // Build requestConfig
    requestConfig.headers = {
        'accept': "application/json"
    }
    // Add the auth token if it is present
    if (getIdToken()) {
        requestConfig.headers.Authorization = getIdToken()
    }

    // Build the body
    if (getLocalStorageItem('session_id')) {
        body.session_id = getLocalStorageItem('session_id')
    }

    return axios.post(endpoint + path, body, requestConfig)
}

/////////////////////////////
// Websocket functionality //
/////////////////////////////

export function getRoom() {
    if (!getEndpoint()) {
        return null
    }
    var room = localStorage.getItem("room")
    if (room !== null) {
        return {name: room, new:false}
        // return room
    }
    else {
        let newroom = newGuid()
        localStorage.setItem("room", newroom)
        return {name: newroom, new:true}
        //return newroom
    }
}

export function clearRoom() {
    localStorage.removeItem("room")
}

////////////////////////
// GUID functionality //
////////////////////////
export function S4() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1); 
}
export function newGuid() {
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}
 


