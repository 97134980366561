import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import landing from '../pages/landing';
import auth from '../pages/auth';

export default function Routes() {
    return (
        <Router>
            <Switch>
                <Route path="/auth" exact strict component={auth} />
                <Route component={landing} />
            </Switch>
        </Router>
    )
}